import { jsPDF } from "jspdf";
import AptosNormal from "../../../utils/Customfonts/Aptos.ttf";
import AptosBold from "../../../utils/Customfonts/Aptos-Bold.ttf";

import autoTable from "jspdf-autotable";
import {
  fetchCertificate,
  fetchCertificateStaticTables,
  fetchStaticReadingRows,
} from "../fetchHandler";
import { drawTables } from "./renderTables";
import {
  certificateBorderLayout,
  certificateEndSection,
  certificatefirstSection,
  certificateFirstSectionHeading,
  certiifcatesecondtable,
  customFontFamily,
  digitallySignConfig,
  footerConfig,
  loadAndAddFont,
  NameAndAddressDetails,
  performanceClaibrationDetails,
  printCertificateSection,
  tableConfiguration,
  totalHeaderHeightWithCalibrationLableorNot,
} from "./certificateConfig";

import {
  layoutConfig,
  printcertificateConfigData,
  printCertificateConfiguration,
} from "./printcertificateConstant";
import {
  createPDFWithSignature,
  detailsofstandards,
  drawfirst2ColumnRowspan,
  drawTextBoldAndNormal,
  drawText,
  drawUUCTable,
  environmenentTable,
  remarksTable,
} from "./commonTable";
import {
  drawDynamicFooter,
  drawLetterFooterImg,
  drawLetterHeadHeaderImg,
  drawWaterMarkImg,
} from "./headerAndfooter";
import axios from "axios";
import { drawDynamicHeader } from "./headerAndfooter";
import { drawAdditionalTable, firstcolumnHeadersecondValue, firstsectionfooter } from "./extraTable";

const drawPageHeader = () => {
  drawLetterHeadHeaderImg();
  drawDynamicHeader();
};

const drawPageFooter = () => {
  drawDynamicFooter();
  drawLetterFooterImg();
};

const initPdfDoc = async () => {
  printCertificateConfiguration.pdfDoc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
    compress: true,
  });

  await Promise.all([
    loadAndAddFont(
      printCertificateConfiguration.pdfDoc,
      AptosNormal,
      "Aptos",
      customFontFamily?.normalFont,
      customFontFamily?.normal
    ),
    loadAndAddFont(
      printCertificateConfiguration.pdfDoc,
      AptosBold,
      "Aptos-Bold",
      customFontFamily?.boldFont,
      customFontFamily?.bold
    ),
  ]);

  // Set default font
  printCertificateConfiguration.pdfDoc.setFont(customFontFamily?.normalFont);
  printCertificateConfiguration.pdfDoc.setFont(customFontFamily?.boldFont);

  commonPageContet();

  layoutConfig.yPositionGlobal =
    totalHeaderHeightWithCalibrationLableorNot.total;
};
const commonPageContet = () => {
  // add page header
  drawPageHeader();
  // add page footer
  drawPageFooter();
  // add watermark
  drawWaterMarkImg();
  // draw border
  printCertificateConfiguration.pdfDoc.setLineWidth( printCertificateConfiguration.lineWidth);
  printCertificateConfiguration.pdfDoc.rect(
    certificateBorderLayout.x,
    certificateBorderLayout.y,
    certificateBorderLayout.width,
    certificateBorderLayout.height
  );
};
export const addNewPage = async () => {
  printCertificateConfiguration.pdfDoc.addPage();
  commonPageContet();
};

const initPage = () => {
  commonPageContet();
};

const setPageSection = async (addNewPages = false) => {
  if (
    layoutConfig.yPositionGlobal >
      printCertificateConfiguration.pageHeight -
        footerConfig.footerimgHeight +
        footerConfig.footertableHeight -
        100 ||
    addNewPages
  ) {
    await addNewPage();
    layoutConfig.yPositionGlobal =
      totalHeaderHeightWithCalibrationLableorNot.total;
  }
};

const certificatefirstpage = async () => {
  await setPageSection();

  // Define the first section heading text
  const textList = certificateFirstSectionHeading(
    printcertificateConfigData.certificateDetailsConfig?.name,
    printcertificateConfigData.certificateDetailsConfig
  );

  // Render the table
  const tableData = textList.map((item) => [item.text]);
  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal - 10,
    margin: tableConfiguration?.margins,
    body: tableData,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      lineColor: printCertificateConfiguration.textColor,
      lineWidth: printCertificateConfiguration.lineWidth,
      fontSize: 17,
      halign: "center",
      lineWidth: 0, // Remove border
      lineColor: false, // Remove border color
      font: customFontFamily?.boldFont,
      cellPadding: { top: 5, left: 5, bottom: 8, right: 5 },
    },
  });

  // Update y position after the table
  layoutConfig.yPositionGlobal =
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 15;

  printCertificateConfiguration.pdfDoc.setFont(
    customFontFamily?.boldFont,
    "bold"
  );
  // Draw sections without wrapping the text
  for (const section of certificatefirstSection) {
    if (section?.type === "table") {
      const content = section.content;
      const tableData = [];
      content.forEach((item, index) => {
        // Only add the first column (index) if 'index' is true
        if (section.index) {
          tableData.push([`${index + 1}.`, item]);
        } else {
          tableData.push([item]); // Only push the content (no index column)
        }
      });
      // Render the table
      autoTable(printCertificateConfiguration.pdfDoc, {
        startY: layoutConfig.yPositionGlobal - 10,
        margin: tableConfiguration?.margins,
        body: tableData,
        theme: "grid",
        styles: {
          fillColor: false,
          textColor: printCertificateConfiguration.textColor,
          lineColor: printCertificateConfiguration.textColor,
          lineWidth: printCertificateConfiguration.lineWidth,
          fontSize: printCertificateConfiguration.fontSizes.heleeven,
          halign: "justify",
          lineWidth: 0, // Remove border
          lineColor: false, // Remove border color
          font: customFontFamily?.normalFont,
          cellPadding: { top: 5, left: 5, bottom: 8, right: 5 },
        },
      });

      // Update y position after the table
      layoutConfig.yPositionGlobal =
        printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 5;
    }
  }

  await firstsectionfooter();
};

const disciplineNameShow = async () => {
  await setPageSection(
    printCertificateSection.disciplineAndReadinTableStartNewpage
  );

  let boldtext = "Discipline : ";
  let normaltext = printcertificateConfigData.certificateDetailsConfig?.name;

  drawTextBoldAndNormal(
    boldtext,
    normaltext,
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    printCertificateConfiguration.align.left,
    printCertificateConfiguration.fontSizes.h2,
    5,
    5
  );
};

const endofcertificate = async () => {
  if(footerConfig?.electronicSign && printcertificateConfigData?.letterHeadAndElectronicAndDraftConfig.electronicSign){
    let boldtext = "This certificate is electronically signed and does not required physical signature";
    drawText(
      boldtext,
      layoutConfig.xPositionGlobal,
      printCertificateConfiguration.pageHeight -
        footerConfig.footerimgHeight -
        footerConfig.endcertificateandimggap - 10,
      "center",
      printCertificateConfiguration.fontSizes.h2,
      5,
      0,
      "normalfont"
    );
  }
  let boldtext = "--------- END OF CALIBRATION CERTIFICATE ---------";
  drawText(
    boldtext,
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal - 35,
    // printCertificateConfiguration.pageHeight -
    //   footerConfig.footerimgHeight -
    //   footerConfig.endcertificateandimggap,
    "center",
    printCertificateConfiguration.fontSizes.h2,
    0,
    0,
    "normalfont"
  );
};

const drawNameandAddress = async () => {
  await setPageSection();
  if (printCertificateSection.dateofPerformancelikeJrpm) {
    const data1 = performanceClaibrationDetails(
      printcertificateConfigData.certificateDetailsConfig
    );
    drawfirst2ColumnRowspan(data1);
  }

  const data2 = NameAndAddressDetails(
    printcertificateConfigData.certificateDetailsConfig,
    printcertificateConfigData.footerSignSettingConfig
  );
  drawfirst2ColumnRowspan(data2);

  const data3 = certiifcatesecondtable(
    printcertificateConfigData.certificateDetailsConfig,
  );
  firstcolumnHeadersecondValue(data3);
};

const certificateLastPage = async () => {
  await setPageSection();

  let maxWidth =
    printCertificateConfiguration.pageWidth -
    printCertificateConfiguration.margin * 2 -
    10;
  // const drawText = (
  //   text,
  //   x,
  //   y,
  //   fontSize,
  //   align = "left",
  //   topSpace = 0,
  //   bottomSpace = 0,
  //   fontFamily
  // ) => {
  //   printCertificateConfiguration.pdfDoc.setFontSize(fontSize); // Use default font
  //   printCertificateConfiguration.pdfDoc.setFont(customFontFamily?.boldFont);

  //   let yPos = y + topSpace;
  //   let textLines = printCertificateConfiguration.pdfDoc.splitTextToSize(
  //     text,
  //     maxWidth
  //   );
  //   // Align the text horizontally
  //   let xPos = x;
  //   let textWidth = printCertificateConfiguration.pdfDoc.getTextWidth(
  //     textLines[0]
  //   );
  //   if (align === "left") {
  //     xPos += 5;
  //   } else if (align === "center") {
  //     xPos = (printCertificateConfiguration.pageWidth - textWidth) / 2;
  //   } else if (align === "right") {
  //     xPos = printCertificateConfiguration.pageWidth - textWidth - x;
  //   }

  //   // Draw each line of text
  //   textLines.forEach((line, index) => {
  //     printCertificateConfiguration.pdfDoc.text(
  //       line,
  //       xPos,
  //       yPos + index * (fontSize + 2),
  //       { maxWidth: maxWidth }
  //     ); // Adjust for line height
  //   });

  //   // Adjust y position for the next line
  //   return yPos + textLines.length * (fontSize + 2) + bottomSpace;
  // };

  let scaledHeight = printCertificateConfiguration.pageWidth / 2;

  // Iterate through the certificateEndSection array
  for (const section of certificateEndSection(printcertificateConfigData.certificateDetailsConfig?.calibrationType)) {
    if (
      section.content ===
      "For any further information, assistance or feedback; please contact:"
    ) {
      await setPageSection(true);
    }

    if (section?.type === "header") {
      let updateX =
        section?.align == "left" ? layoutConfig.xPositionGlobal : scaledHeight;
      // layoutConfig.yPositionGlobal = drawText(
      //   section?.content,
      //   updateX,
      //   layoutConfig.yPositionGlobal,
      //   printCertificateConfiguration.fontSizes.h4,
      //   section?.align,
      //   5,
      //   2
      //   // fontFamilyConfig.bold
      // );
      drawText(
        section?.content,
        updateX + 3,
        layoutConfig.yPositionGlobal,
        "left",
        printCertificateConfiguration.fontSizes.h4,
        2,
        5,
      );
    } else if (section?.type === "table") {
      const content = section.content;
      const tableData = [];
      content.forEach((item, index) => {
        // Only add the first column (index) if 'index' is true
        if (section.index) {
          tableData.push([`${index + 1}.`, item]);
        } else {
          tableData.push([item]); // Only push the content (no index column)
        }
      });
      // Render the table
      autoTable(printCertificateConfiguration.pdfDoc, {
        startY: layoutConfig.yPositionGlobal - 10,
        margin: tableConfiguration?.margins,
        body: tableData,
        theme: "grid",
        styles: {
          fillColor: false,
          textColor: printCertificateConfiguration.textColor,
          lineColor: printCertificateConfiguration.textColor,
          lineWidth: printCertificateConfiguration.lineWidth,
          fontSize: printCertificateConfiguration.fontSizes.h4,
          halign: "justify",
          lineWidth: 0, // Remove border
          lineColor: false, // Remove border color
          font: customFontFamily?.normalFont,
          cellPadding: { top: 1, left: 5, bottom: 1, right: 5 },
        },
        tableLineColor: printCertificateConfiguration.textColor,
        tableLineWidth: printCertificateConfiguration.lineWidth,
        bodyStyles: {
            lineWidth: 0,
        },
      });

      // Update y position after the table
      layoutConfig.yPositionGlobal =
        printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
    }
  }
  if (printCertificateSection.jrpmtable) {
    await endofcertificate();
  }
};

export const generatePDF = async ({
  certificateId,
  footerSignSetting,
  letterHeadAndElectronicAndDraft,
  remarksCondition,
  setDownloadPdfloading,
}) => {
  try {
    setDownloadPdfloading(true);

    // fetch certifcateview
    const fetchCertificateResult = await fetchCertificate(certificateId);
    printcertificateConfigData.certificateDetailsConfig = {
      ...fetchCertificateResult?.certificateDetails,
    };
    printcertificateConfigData.standardsConfig = [
      ...fetchCertificateResult?.standards,
    ];
    printcertificateConfigData.certificateId = certificateId;
    printcertificateConfigData.footerSignSettingConfig = footerSignSetting;
    printcertificateConfigData.letterHeadAndElectronicAndDraftConfig =
      letterHeadAndElectronicAndDraft;

    const staticTables = await fetchCertificateStaticTables(
      printcertificateConfigData.certificateDetailsConfig?.instrumentId,
      printcertificateConfigData.certificateDetailsConfig?.deletedTableIds
    );
    const staticCertificateReadings = await fetchStaticReadingRows(
      printcertificateConfigData.certificateDetailsConfig?.id
    );

    await initPdfDoc();

    // draw name and address details
    if (printCertificateSection.firstpagelikeJRPM) {
      await certificatefirstpage();
    }
    await drawNameandAddress();
    await drawUUCTable(setPageSection);
    if (printCertificateSection.jrpmtable) {
      await drawAdditionalTable(setPageSection);
    }
    await environmenentTable(setPageSection);
    await detailsofstandards(setPageSection);
    await certificateLastPage();
    await disciplineNameShow();

    // draw tables
    layoutConfig.yPositionGlobal = await drawTables(
      printCertificateConfiguration.pdfDoc,
      layoutConfig.yPositionGlobal,
      staticTables,
      staticCertificateReadings,
      {
        margin: printCertificateConfiguration.margin,
        tableMargin: {
          top: totalHeaderHeightWithCalibrationLableorNot.total,
          right: printCertificateConfiguration.margin,
          left: printCertificateConfiguration.margin,
          bottom:
            footerConfig.footerimgHeight +
            footerConfig.footertableHeight +
            footerConfig.footerandbodygap,
        },
        initPage: initPage,
      }
    );

    await remarksTable(remarksCondition, setPageSection, initPage);

    if (digitallySignConfig.digitallySignONorOFF) {
      await createPDFWithSignature(setPageSection);
    }

    if (printCertificateSection.lastpagelikeJRPM) {
      await certificateLastPage();
    }

    await endofcertificate();

    const totalPages = printCertificateConfiguration.pdfDoc.getNumberOfPages();
    for (let page = 1; page <= totalPages; page++) {
      printCertificateConfiguration.pdfDoc.setPage(page);
      let content = `Page No : ${String(page).padStart(2, "0")} of ${String(
        totalPages
      ).padStart(2, "0")}`;
      drawText(
        content,
        printCertificateConfiguration.pageWidth - 85,
        printCertificateConfiguration.pageHeight -
          footerConfig.footerimgHeight -
          footerConfig.endcertificateandimggap,
        "left",
        printCertificateConfiguration.fontSizes.h2,
        5,
        0
      );
    }

    // sign the certificate
    if (digitallySignConfig.digitallySignONorOFF) {
      PDFSigner(
        printCertificateConfiguration.pdfDoc,
        printcertificateConfigData,
        setDownloadPdfloading
      );
    } else {
      // save and download pdf
      printCertificateConfiguration.pdfDoc.save(
        `${printcertificateConfigData.certificateDetailsConfig?.certificateNumber}.pdf`
      );
      setDownloadPdfloading(false);
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
    setDownloadPdfloading(false);
  }
};

const PDFSigner = async (
  pdfDoc,
  printcertificateConfigData,
  setDownloadPdfloading
) => {
  try {
    // Convert the jsPDF object to a Blob
    // Step 2: Convert PDF to Base64
    const pdfBlob = pdfDoc.output("blob");

    // Step 3: Create a FormData object
    const formData = new FormData();
    formData.append(
      "pdf",
      new File([pdfBlob], "document.pdf", { type: "application/pdf" })
    );

    // Step 4: Send the request with multipart/form-data
    const response = await axios.post(
      "https://labcity.bfiinfotech.co.in/api/pdfsigner",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      }
    );

    // Process the response and save the signed PDF
    // If the response is a Blob, create a URL to download the file
    const downloadUrl = URL.createObjectURL(response.data);

    // Create a temporary link element to trigger the download
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = `${printcertificateConfigData.certificateDetailsConfig?.certificateNumber}.pdf`; // The file name when downloaded
    document.body.appendChild(link);
    link.click();
    setDownloadPdfloading(false);
    // Clean up the URL after downloading
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error signing the PDF:", error);
    setDownloadPdfloading(false);
  }
};
