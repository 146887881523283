
import {
  accuracyFormat,
  DateisValid,
  parseRange,
} from "../../../utils/components/accuracyandlcandrangesshow";

export let printCertificateSection = {
  firstpagelikeJRPM: false,
  lastpagelikeJRPM: false,
  dateofPerformancelikeJrpm: false,
  disciplineAndReadinTableStartNewpage: true,
  jrpmtable: false,
  readingTableSerialNoColumnShow: false,
};

export const headerConfig = {
  headerCALIBRATIONCERTIFICATE: true,
  headerCALIBRATIONCERTIFICATEgap: 10,
  headerCALIBRATIONCERTIFICATEwithoutgap: 7,
  lettterImgHeight: 125,
  headerTableHeight: 30,
  headerandbodygap: 10,
  tableRowfontSizes: 9,
  cellPadding: { top: 4, left: 5, bottom: 4, right: 5 },
};

export const calculateHeader = {
  headerCALIBRATIONCERTIFICATExpostion:
    headerConfig.headerCALIBRATIONCERTIFICATE
      ? headerConfig.lettterImgHeight +
        headerConfig.headerCALIBRATIONCERTIFICATEgap
      : headerConfig.lettterImgHeight +
        headerConfig.headerCALIBRATIONCERTIFICATEwithoutgap,
  headerCALIBRATIONCERTIFICATExpostionafter:
    headerConfig.lettterImgHeight +
    headerConfig.headerCALIBRATIONCERTIFICATEgap +
    10,
};

export const totalHeaderHeightWithCalibrationLableorNot = {
  total: headerConfig.headerCALIBRATIONCERTIFICATE
    ? headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 20
    : headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 7,
};

export const footerConfig = {
  electronicSign:false,
  footerCALIBRATIONCERTIFICATE: false,
  footerimgRemove:true,
  footerlinewidth: 0,
  footerimgHeight: 30,
  footertableHeight: 50,
  footerandbodygap: 57,
  endcertificateandimggap: 5,
  stampui: false,
};
let pageHeight = 841;
export const certificateBorderLayout = {
  x: 15,
  y: totalHeaderHeightWithCalibrationLableorNot.total - 5,
  width: 565,
  height:
    pageHeight -
    footerConfig.footerimgHeight -
    footerConfig.footertableHeight -
    footerConfig.footerandbodygap -
    totalHeaderHeightWithCalibrationLableorNot.total +
    6,
  // height: 570
};

export const tableConfiguration = {
  margins: { top: 0, right: 25, bottom: 0, left: 25 },
};

export const tableConfigurationheaderandfooter = {
  margins: { top: 0, right: 15, bottom: 0, left: 15 },
};

export const fontFamilyConfig = {
  useFont: "Aptos",
  bold: "bold",
  normal: "normal",
};

export const waterMarkImgConfig = {
  logoWidth: 150,
  logoHeight: 160,
};

export const environmentTableConfig = {
  spacebetweenHeadingandTable: 5,
  innerBorder: 0,
  cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
};

export const loadAndAddFont = async (
  pdfDoc,
  fontUrl,
  fontNameTTF,
  fontName,
  fontStyle
) => {
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const chunkSize = 8192; // Process in chunks for large buffers
    for (let i = 0; i < bytes.length; i += chunkSize) {
      binary += String.fromCharCode(...bytes.subarray(i, i + chunkSize));
    }
    return btoa(binary);
  };

  const fontResponse = await fetch(fontUrl);
  const fontBuffer = await fontResponse.arrayBuffer();
  const fontBase64 = arrayBufferToBase64(fontBuffer);

  pdfDoc.addFileToVFS(`${fontNameTTF}.ttf`, fontBase64);
  pdfDoc.addFont(`${fontNameTTF}.ttf`, fontName, fontStyle);
};

export const customFontFamily = {
  bold: "bold",
  normal: "normal",
  boldFont: "AptosBold",
  normalFont: "AptosNormal",
};

export const digitallySignConfig = {
  digitallySignONorOFF: false,
  signatureTextContnet: [
    `REVA PHOENIX LABS AND CONSULTANTS`,
    `PRIVATE LIMITED`,
    `Date:  ${new Date()
      .toLocaleString("en-GB", { timeZone: "Asia/Kolkata" })
      .replace(",", "")} +05:30`,
    `Reason: Calibration Certificate`,
    `Location: Chennai`,
  ],
};



export let certificateEndSection = (caltype) => [
  {
    type: "header",
    align: "left",
    content: "Note:",
  },
  {
    type: "table",
    index: true,
    content: caltype == 3 ? [
      "This Calibration Certificate relates only to the above DUC & Reported results are valid at the time of and under the stated conditions of measurements.",
      "Partial Publication/ reproduction of this Certificate in any form is not permitted without the written consent of TransCal Technologies LLP.",
      "Errors if any, in this Certificate shall be brought to notice within 45 days from the date of this Certificate.",
      "Calibration of the DUC are traceable to National/International Standards.",
      "Corrections/erasing invalidate the Calibration Certificate.",
      "The Decision Rule:",
      "Simple Conformity Decision: 'COMPLY' indicates measured value is within or on threshold of Acceptance limit/Accuracy. 'DOESN'T COMPLY' indicates measured value is beyond the threshold of Acceptance limit/Accuracy. '--' indicates no Acceptance limit/Accuracy furnished."
    ] : [
      "This Calibration Certificate relates only to the above DUC & Reported results are valid at the time of and under the stated conditions of measurements.",
      "Partial Publication/ reproduction of this Certificate in any form is not permitted without the written consent of TransCal Technologies LLP.",
      "Errors if any, in this Certificate shall be brought to notice within 45 days from the date of this Certificate.",
      "Measurement Uncertainty reported is at approximately 95 % confidence level with k=2; Units of Measurement results & Measurement Uncertainty are same as that of range selected - Unless otherwise indicated.",
      "Calibration of the DUC are traceable to National/International Standards.",
      "Corrections/erasing invalidate the Calibration Certificate.",
      "Unless otherwise specified the Measurement Data reported is 'As Found'-Without any adjustment.",
      "The Decision Rule:",
      "Simple Conformity Decision: Decision Without Considering Measurement Uncertainty, 'COMPLY' indicates measured value is within or on threshold of Tolerance limit/Accuracy. 'DOESN'T COMPLY' indicates measured value is beyond threshold of Tolerance limit/Accuracy. '__' indicates no tolerance limit/Accuracy furnished."
    ]
    }
    ];

// Define a function to generate the textList array
export const certificateFirstSectionHeading = (
  disciplineName,
  certificateDetails
) => {
  return [
    { text: disciplineName || "", fontSize: 17 },
    { text: "Calibration Certificate", fontSize: 17 },
    { text: "For", fontSize: 17 },
    {
      text: certificateDetails?.requestedname
        ? certificateDetails?.requestedname
        : certificateDetails?.instrumentName || "",
      fontSize: 17,
    },
  ];
};

export let certificatefirstSection = [];

// Exporting MasterEQPDetails
export const MasterEQPDetails = (standards) => ({
  heading: "Standards Used",
  headingFontSize: 9.5,
  headingBottomSpace: 0,
  headingTopSpace: 0,
  headingAlign: "left",
  spacebetweenHeadingandTable: 5,
  cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
  tableTextfontSize: 9.5,
  tableValueAlign: "center",
  keys: [
    "SI. No.",
    "Nomenclature",
    "Make",
    "Model",
    "SI No",
    "Certificate No",
    "Validity",
  ],
  values: standards.map((item, index) => [
    index + 1,
    item.standardName,
    item.make,
    item.model,
    item.serialNo,
    item.certificateNumber,
    DateisValid(item.validUpto),
    // `${item.certificateNumber} Valid till ${DateisValid(item.validUpto)}`,
  ]),
});

export const UUCDetails = (certificateDetails) => {
  const getExtraColumn = (key) => {
    const value = certificateDetails?.extraColumnsParse?.[key];
    return value ? { key, value } : null; // Only return key-value pair if there's a value
  };

  const standardFields = [
    {
      key: "Nomenclature",
      value:
        certificateDetails?.requestedname || certificateDetails?.instrumentName,
    },
    {
      key: "Procedure No.",
      value: certificateDetails?.calProcRefNo
        ? certificateDetails?.calProcRefNo
        : certificateDetails?.calibrationProcedureNo,
    },
    { key: "Range", value: parseRange(certificateDetails?.ranges) },
    { key: "Receipt Date", value:  DateisValid(certificateDetails?.receiptDate) },
    {
      key: "Resolution",
      value: parseRange(certificateDetails?.lc),
    },
    { key: "DUC Condition on Receipt", value: certificateDetails?.ConOfDuc },
    { key: "Acceptance Limit", value: accuracyFormat(certificateDetails?.accuracy) },
    {
      key: "Cal At",
      value: certificateDetails?.location == 1 ? "In House" : "Onsite",
    },
    {
      key: "Model/Make",
      value: certificateDetails?.model && certificateDetails?.make 
        ? `${certificateDetails?.model} / ${certificateDetails?.make}` 
        : `${certificateDetails?.model || ""}${certificateDetails?.make || ""}`
    },
    { key: "Location", value: certificateDetails?.locationOfInstrument },
    { key: "SI. No.", value: certificateDetails?.serialNumber },
    { key: "Operating Range", value: parseRange(certificateDetails?.operatingRange) },
    { key: "ID No", value: certificateDetails?.DUCID },
    // { key: "SRF No", value: certificateDetails?.certificatesserviceReqNumber },
    // { key: "Model", value: certificateDetails?.model },
    // {
    //   key: "Standard Reference",
    //   value: certificateDetails?.isaStandard
    //     ? certificateDetails?.isaStandard
    //     : certificateDetails?.referenceStandards,
    // },
  ];

  // Extra fields
  const extraFields = ["Material"];

  // Add extra fields only if they have a value
  const extraPanelFields = extraFields
    .map(getExtraColumn)
    .filter((item) => item !== null);
  // Filter standard fields to include only those with values
  const filteredStandardFields = standardFields.filter((field) => field.value);
  // Combine filtered standard fields with extra fields (no special ordering)
  const allFields = [...filteredStandardFields, ...extraPanelFields];
  // Create rows from the fields, pairing key-value pairs
  const rows = [];
  for (let i = 0; i < allFields.length; i += 2) {
    rows.push([allFields[i], allFields[i + 1] || null]); // Add extra field as null if there's no pair
  }

  return {
    heading: "Details of device under calibration (DUC)",
    headingFontSize: 9.5,
    headingBottomSpace: 0,
    headingTopSpace: 5,
    headingAlign: "left",
    spacebetweenHeadingandTable: 5,
    innerBorder: 0,
    cellPadding: { top: 1, left: 5, bottom: 1, right: 5 },
    tableTextfontSize: 9.5,
    middleColumn: true,
    rows,
  };
};

export const NameAndAddressDetails = (certificateDetails,clientInfo) => {
  let ISendcalibrationDate  = certificateDetails?.endCalibrationDate ?  `${DateisValid(certificateDetails?.calibrationDate)} to ${DateisValid(certificateDetails?.endCalibrationDate)}` : DateisValid(certificateDetails?.calibrationDate);

  return {
    leftPanel: {
      keys: ["Customer Name & Address"],
      values: [
        `${clientInfo?.clientName || ""}\n${
          clientInfo?.newAddressArray?.[0]?.address || ""
        }`,
      ],
    },
    rightPanel: {
      keys: ["Date of Receipt", "Date Calibration", "Due Date", "Date Issue"],
      values: [
        DateisValid(certificateDetails?.receiptDate),
        ISendcalibrationDate,
        certificateDetails?.calFrequency == "Not Required"
          ? "-"
          : certificateDetails?.calFrequency == "Customer Defined" 
          ? "Customer Defined" 
          : DateisValid(certificateDetails?.nextDueDate),
        certificateDetails?.dateOfIssue
          ? DateisValid(certificateDetails?.dateOfIssue)
          : DateisValid(certificateDetails?.approvedDate),
      ],
    },
    cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
    tableTextfontSize: 9.5,
    firsttwocolumnrowSpan: false
  };
};

export const performanceClaibrationDetails = (certificateDetails) => ({
  leftPanel: {
    keys: ["Date of Performance of Calibration"],
    values: [DateisValid(certificateDetails?.calibrationDate)],
  },
  rightPanel: {
    keys: ["Date of Issue", "Recommended Due Date"],
    values: [
      certificateDetails?.dateOfIssue
        ? DateisValid(certificateDetails?.dateOfIssue)
        : DateisValid(certificateDetails?.approvedDate),
        certificateDetails?.calFrequency == "Not Required" ? "-" : certificateDetails?.calFrequency == "Customer Defined" ? "Customer Defined" : DateisValid(certificateDetails?.nextDueDate),
    ],
  },
});

export const dynamicHeader = (certificateDetailsConfig, calType) => {
  const headerKeysAndValues = calType != 1
  ? [{ keys: "Calibration Certificate No", values: certificateDetailsConfig?.certificateNumber }]
  : [
      { keys: "ULR Number", values: certificateDetailsConfig?.ULRNo?.replace("\n", "") },
      { keys: "Calibration Certificate No", values: certificateDetailsConfig?.certificateNumber },
    ];

    
    const leftAndRightKeyvalue = headerKeysAndValues.filter((field) => field.values);
    const rows = [];
    for (let i = 0; i < leftAndRightKeyvalue.length; i += 2) {
      rows.push([leftAndRightKeyvalue[i], leftAndRightKeyvalue[i + 1] || null]);
    }

  return {
    mergeKeyValue:true,
    headerKeysAndValues,
    firstcolheadersecondcolvalue:false,
    firstrowHeaderscondValue:false,   
    rows
  };
};

export const certiifcatesecondtable = (certificateDetails) => {
  let ISendcalibrationDate  = certificateDetails?.endCalibrationDate ?  `${DateisValid(certificateDetails?.calibrationDate)} to ${DateisValid(certificateDetails?.endCalibrationDate)}` : DateisValid(certificateDetails?.calibrationDate);
  let column = 6;
  let firstRow = [
    { key: "Customer’s Reference", value: certificateDetails?.dcNumber || "" },
    { key: "SRF No.", value: certificateDetails?.certificatesserviceReqNumber || ""},
    {
      key: "Date",
      value: DateisValid(certificateDetails?.dcDate),
    },
  ];
  let secondRow = [
    { key: "Calibrated On", value:  ISendcalibrationDate },
    { key: "Date of Issue", value: certificateDetails?.dateOfIssue
      ? DateisValid(certificateDetails?.dateOfIssue)
      : DateisValid(certificateDetails?.approvedDate)
     },
    {
      key: "Recommended Calibration Due Date",
      value: certificateDetails?.calFrequency == "Not Required"
      ? "-"
      : certificateDetails?.calFrequency == "Customer Defined" 
      ? "Customer Defined" 
      : DateisValid(certificateDetails?.nextDueDate),
    },
  ];
  return {
    firstRow,
    secondRow,
    column,
    cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
    tableTextfontSize: 9.5,
  };
};
