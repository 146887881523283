import autoTable from "jspdf-autotable";
import { generateAndAddQRCodes } from "./commonTable";
import { layoutConfig, printcertificateConfigData, printCertificateConfiguration } from "./printcertificateConstant";
import { customFontFamily, tableConfiguration } from "./certificateConfig";

export const firstsectionfooter = async () => {
  await generateAndAddQRCodes(layoutConfig.xPositionGlobal, 80, true, true);

  let tables = [];
  let tablerows = [
    {
      content: "Calibration Procedure",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
      },
    },
    {
      content: "As Received Conditions",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
      },
    },
    {
      content: "As Shipped Condition",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
      },
    },
  ];
  tables.push(tablerows);

  tablerows = [
    printcertificateConfigData.certificateDetailsConfig?.calProcRefNo ||
      printcertificateConfigData.certificateDetailsConfig
        ?.calibrationProcedureNo ||
      "",
    printcertificateConfigData.certificateDetailsConfig?.ConOfDuc || "",
    "Cleaned & Calibrated",
  ];
  tables.push(tablerows);

  // Using autoTable to add the table
  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tables,
    theme: "grid",
    headStyles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      fontSize: printCertificateConfiguration.fontSizes.h2,
      font: customFontFamily?.boldFont,
    },
    styles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      lineColor: printCertificateConfiguration.textColor,
      lineWidth: printCertificateConfiguration.lineWidth,
      fontSize: printCertificateConfiguration.fontSizes.h2,
      halign: "left",
      font: customFontFamily?.normalFont,
    },
  });

  layoutConfig.yPositionGlobal +=
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const drawAdditionalTable = async (setPageSection) => {
  await setPageSection();
  const tablesdata = [
    [
      {
        content: "Condition of unit under calibration on receipt",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      printcertificateConfigData.certificateDetailsConfig?.ConOfDuc || "",
    ],
    [
      {
        content: "Method used - Reva Phoenix Document Number",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      printcertificateConfigData.certificateDetailsConfig?.calProcRefNo ||
        printcertificateConfigData.certificateDetailsConfig
          ?.calibrationProcedureNo ||
        "",
    ],
    [
      {
        content: "Reference Standard (International / National)",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      printcertificateConfigData.certificateDetailsConfig?.isaStandard ||
        printcertificateConfigData.certificateDetailsConfig
          ?.referenceStandards ||
        "",
    ],
  ];
  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tablesdata,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      lineColor: printCertificateConfiguration.textColor,
      lineWidth: printCertificateConfiguration.lineWidth,
      fontSize: printCertificateConfiguration.fontSizes.h2,
      halign: "left",
      valign: "middle",
      font: customFontFamily?.normalFont,
    },
  });
  layoutConfig.yPositionGlobal =
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const firstcolumnHeadersecondValue = async (panels) => {
  const { firstRow, secondRow, column } = panels;
  const tableData = [];
  const combinedRows = [...firstRow, ...secondRow];

  let rowData = [];
  combinedRows.forEach((item, index) => {
    rowData.push(
      {
        content: item.key,
        styles: { halign: "center", font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold, valign: "middle" },
      },
      { content: item.value, styles: { halign: "center", valign: "middle" } }
    );

    if (rowData.length === column) {
      tableData.push(rowData);
      rowData = [];
    }
  });

  if (rowData.length > 0) {
    tableData.push(rowData);
  }

  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableData,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: printCertificateConfiguration.lineWidth,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printCertificateConfiguration.cellPaddingTable,
      fontSize: panels.tableTextfontSize || printCertificateConfiguration.fontSizes.h2,
    },
  });

  layoutConfig.yPositionGlobal =
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};
